<template>
  <b-row>
    <b-col sm="12">

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Editor de Código</h4>            
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>       
            <prism-editor v-model="crud.form.code" language="php" class="php-editor-code" :readonly="php && php.blocked"></prism-editor>                        
          </b-col>
        </b-row>

        <template v-slot:footer>
          <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)" class="pull-left">
            <i class="fa fa-angle-double-left"></i>
            Volver
          </b-button>

          <b-button variant="dark" @click="save()" size="sm" class="pull-right" v-if="php && !php.blocked">Guardar</b-button>          
        </template>         
      </b-card>      
    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'   
  import moment from 'moment'
  
  import PrismEditor from 'vue-prism-editor'
  import Prism from "prismjs";  
  import "prismjs/themes/prism.css";  
  import "prismjs/components/prism-markup-templating";  
  import 'prismjs/components/prism-php';

  export default {  
    components: {      
      PrismEditor,
    },          
    data: () => {
      return {      
        access: {
          module_id: Modules.PHP,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudPHPCode',
          elements: {}
        },
        crud: {
          form: {
            id: '',
            code: '',
            blocked: false,            
          },          
        }, 
        php: null         
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {              
      this.load()
    },
    methods: {  
      load() {
        if(!this.$route.params.id) {
          this.$router.go(-1)
        }

        this.crud.form.id = this.$route.params.id  
        var result = serviceAPI.mostrarPHP(this.crud.form.id)

        result.then((response) => {
          var data = response.data
          this.crud.form.id = data.id
          this.crud.form.code = data.code

          this.php = data
          console.log(this.php)
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });        
      }, 
      save() {
        let loader = this.$loading.show();
        
        var result = serviceAPI.guardarCodePHP(this.crud.form);
                
        result.then((response) => {                    
          loader.hide()
          this.load()
          this.$awn.success("Código guardado");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },           
    }      
  }
</script>
<style>
  .php-editor-code {
    height: 500px;
  }
  .php-editor-code pre {
    height: 500px;
  }
</style>